// @ts-nocheck
export default class TicketEntity {
    static getEntityData(elem: any) {
        console.log("get ticket data", elem)
        let d = {
            uuid: elem.querySelector("#ticket_uuid") ? (elem.querySelector("#ticket_uuid") as HTMLInputElement).value : null,
            title: (elem.querySelector("#ticket_title") as HTMLInputElement).value,
        };
        if (elem.classList.contains("app-ticket")) {
            let customerId = null;
            let customerLocationId = null;
            let customerContactId = null;
            if (elem.querySelector("#ticket_customer_id option:checked")) {
                customerId = (elem.querySelector("#ticket_customer_id option:checked") as HTMLInputElement).value
            } else if (elem.querySelector("#ticket_customer_id")) {
                customerId = elem.querySelector("#ticket_customer_id").value
            }

            if (elem.querySelector("#ticket_customer_location_id option:checked")) {
                customerLocationId = (elem.querySelector("#ticket_customer_location_id option:checked") as HTMLInputElement).value
            } else if (elem.querySelector("#ticket_customer_location_id")) {
                customerLocationId = elem.querySelector("#ticket_customer_location_id").value
            }

            if (elem.querySelector("#ticket_customer_contact_id option:checked")) {
                customerContactId = (elem.querySelector("#ticket_customer_contact_id option:checked") as HTMLInputElement).value
            } else if (elem.querySelector("#ticket_customer_contact_id")) {
                customerContactId = elem.querySelector("#ticket_customer_contact_id").value
            }
            d = {...d,
                parent_id: elem.querySelector("#ticket_parent_id option:checked") ? (elem.querySelector("#ticket_parent_id option:checked") as HTMLInputElement).value : null,

                customer_reference: (elem.querySelector("#ticket_customer_reference") as HTMLInputElement).value,
                description: (elem.querySelector("#ticket_description") as HTMLInputElement).value,
                description_internal: (elem.querySelector("#ticket_description_internal") as HTMLInputElement).value,
                status_id: (elem.querySelector("#ticket_status_id option:checked") as HTMLInputElement).value,
                priority_id: (elem.querySelector("#ticket_priority_id option:checked") as HTMLInputElement).value,
                category_id: elem.querySelector("#ticket_category_id option:checked") ? (elem.querySelector("#ticket_category_id option:checked") as HTMLInputElement).value : null,

                customer_id: customerId,
                customer_location_id: customerLocationId,
                customer_contact_id: customerContactId,

                assigned_id: elem.querySelector("#ticket_assignee_id option:checked") ? (elem.querySelector("#ticket_assignee_id option:checked") as HTMLInputElement).value : null,
                project_id: elem.querySelector("#ticket_project_id option:checked") ? (elem.querySelector("#ticket_project_id option:checked") as HTMLInputElement).value : null,
                order_id: elem.querySelector("#ticket_order_id option:checked") ? (elem.querySelector("#ticket_order_id option:checked") as HTMLInputElement).value : null,
                order_name: elem.querySelector("#ticket_order_id option:checked") ? (elem.querySelector("#ticket_order_id option:checked") as HTMLInputElement).innerHTML : null,
                ticket_category_id: elem.querySelector("#ticket_category_id option:checked") ? (elem.querySelector("#ticket_category_id option:checked") as HTMLInputElement).value : null,
                ticket_queue_id: Array.from(elem.querySelectorAll("#ticket_queue_id option:checked")).map(o => o.value)
            }
        }
        return d
    }
}